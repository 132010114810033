import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AuthContext } from "context/auth-context/auth.context";
import { useContext } from "react";
import { updatePublishing } from "./publishing.service";

enum KeyQueryPublishing {
  getById = "publishingGetClientByIds",
  getDesign = "getDesign",
}
export const useUpdatePublishing = () => {
  const { clientId } = useContext(AuthContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ typePublishing, select, data }: any) =>
      updatePublishing({ clientId, typePublishing, select, data }),
    onSuccess: (result) => {
      queryClient.setQueryData([KeyQueryPublishing.getById, clientId], result);
    },
  });
};
