import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AuthContext } from "context/auth-context/auth.context";
import { useContext } from "react";
import { Design, ETypeDesign, ISection } from "./design.type";
import { updateDesign } from "./design.service";
import { SELECT_DESIGN } from "types/client.type";

enum KeyQueryDesign {
  getById = "getClientByIds",
  getDesign = "getDesign",
}

interface IUseDesign {
  typeDesign: ETypeDesign;
  select: string;
  data: Design | FormData; // Modifier le type ici
}

export const useUpdateDesign = () => {
  const { clientId } = useContext(AuthContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      typeDesign,
      select,
      data,
    }: {
      typeDesign: ETypeDesign;
      select?: string;
      data: any;
    }) => {
      const formData = new FormData();

      console.log({ data });
      // 1. Collecter les fichiers des sections de type 'image'
      const imageSections = data?.sections?.filter(
        (section: ISection) => section.type === "image" && section.imageUrl instanceof File
      );

      // 2. Ajouter les fichiers au FormData
      imageSections?.forEach((section: ISection) => {
        formData.append("images", section.imageUrl);
      });

      // 3. Préparer les sections en préservant la structure originale
      const preparedSections = data?.sections?.map((section: ISection) => {
        // Ne manipuler imageUrl que pour les sections de type 'image'
        if (section.type === "image") {
          return {
            ...section,
            imageUrl: section.imageUrl instanceof File ? {} : section.imageUrl,
          };
        }
        // Pour les autres types, retourner la section telle quelle sans ajouter imageUrl
        const { imageUrl, ...rest } = section;
        return rest;
      });

      // 4. Ajouter les données JSON
      formData.append(
        "data",
        JSON.stringify({
          [typeDesign]: { ...data[typeDesign], sections: preparedSections },
        })
      );
      console.log({ formData });
      return updateDesign({
        clientId,
        typeDesign,
        select,
        data: formData,
      });
    },
    onSuccess: (result) => {
      queryClient.setQueryData([KeyQueryDesign.getById, clientId, SELECT_DESIGN], result);
    },
  });
};
