import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Icon } from "@mui/material";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import SelectColor from "components/Select/SelectColor";
import { ISection } from "pages/design/design.type";
import { memo } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  section: ISection;
  setFieldValue: any;
  index: number;
  handleDeleteSection: (index: number) => void;
}

const SectionSearchBar = memo(
  ({ section, setFieldValue, index, handleDeleteSection }: Props): JSX.Element => {
    const { t } = useTranslation();
    const { bgColor, textColor } = section;

    return (
      <MDBox>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Grid container spacing={3}>
              <Grid item xs={10}>
                <MDTypography variant="body2">{t("design.section.search.title")}</MDTypography>
              </Grid>
              <Grid item>
                <MDBox
                  color="error"
                  size="medium"
                  onClick={() => handleDeleteSection(index)}
                  sx={{ height: 20 }}
                  alignItems={"center"}
                  alignContent={"center"}
                >
                  <Icon>delete</Icon>
                </MDBox>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <SelectColor
                  fieldName={`sections[${index}].bgColor`}
                  label={t("form.bgColor")}
                  value={bgColor || "#ffffff"}
                  setFieldValue={setFieldValue}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <SelectColor
                  fieldName={`sections[${index}].textColor`}
                  label={t("form.textColor")}
                  value={textColor || "#000000"}
                  setFieldValue={setFieldValue}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </MDBox>
    );
  }
);

export default SectionSearchBar;
