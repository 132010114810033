import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { ISection } from "pages/design/design.type";
import { memo } from "react";

interface Props {
  section?: ISection;
}

const MockupSectionText = memo(({ section }: Props): JSX.Element => {
  const custom = {
    bgColor: section?.bgColor || "#ffffff",
    textColor: section?.textColor || "#ffffff",
  };

  return (
    <MDBox p={2} bgColor={custom.bgColor}>
      <MDTypography
        variant="button"
        style={{ color: custom.textColor }} // Ajout du style pour la couleur du texte
        dangerouslySetInnerHTML={{ __html: section?.htmlContent || "" }}
      />
    </MDBox>
  );
});

export default MockupSectionText;
