import { Card, CircularProgress, Grid } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import { ETypePublishing, IPublishingGeneralForm } from "../publishing.type";
import { useUpdatePublishing } from "../publishing.hook";
import FormField from "components/FormField/FormField";
import MDTypography from "components/MDTypography";

const PublishingGeneral = () => {
  const { t } = useTranslation();
  const updatePublishing = useUpdatePublishing();
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const initialValues: IPublishingGeneralForm = {
    appName: "",
    shortDescription: "",
    longDescription: "",
    category: "",
    keywords: "",
    supportEmail: "",
    privacyUrl: "",
  };

  const validationSchema = Yup.object().shape({
    appName: Yup.string().required(t("form.required")),
    shortDescription: Yup.string().max(30, t("form.max30")).required(t("form.required")),
    longDescription: Yup.string().max(4000, t("form.max4000")).required(t("form.required")),
    category: Yup.string().required(t("form.required")),
    keywords: Yup.string().required(t("form.required")),
    supportEmail: Yup.string().email(t("form.email")).required(t("form.required")),
    privacyUrl: Yup.string().url(t("form.url")).required(t("form.required")),
  });

  const handleSubmit = async (
    values: IPublishingGeneralForm,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    try {
      await updatePublishing.mutateAsync({
        typePublishing: ETypePublishing.GENERAL,
        data: values,
      });
      setOpenAlert(true);
    } catch (err: any) {
      setError(err.message);
    }
    setSubmitting(false);
  };

  return (
    <MDBox my={3}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, errors, dirty, touched }) => (
          <Form>
            <MDBox my={3}>
              <MDBox mb={1}>
                <Grid container spacing={3} alignItems="center">
                  <Grid item xs={12} lg={6}>
                    <MDTypography variant="h4" fontWeight="medium">
                      {t("publishing.tabs.general")}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <MDBox display="flex" justifyContent="flex-end">
                      <MDButton
                        disabled={!dirty || isSubmitting}
                        type="submit"
                        variant="gradient"
                        color="info"
                      >
                        {t("form.validate")}
                        {isSubmitting && (
                          <CircularProgress
                            size={24}
                            sx={{
                              color: "inherit",
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              marginTop: "-12px",
                              marginLeft: "-12px",
                            }}
                          />
                        )}
                      </MDButton>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              <Card style={{ overflowY: "auto", padding: 16 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormField
                      fullWidth
                      name="appName"
                      label={t("publishing.form.appName")}
                      error={errors.appName && touched.appName}
                      success={!errors.appName && touched.appName}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormField
                      fullWidth
                      multiline
                      rows={2}
                      name="shortDescription"
                      label={t("publishing.form.shortDesc")}
                      error={errors.shortDescription && touched.shortDescription}
                      success={!errors.shortDescription && touched.shortDescription}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormField
                      fullWidth
                      multiline
                      rows={4}
                      name="longDescription"
                      label={t("publishing.form.longDesc")}
                      error={errors.longDescription && touched.longDescription}
                      success={!errors.longDescription && touched.longDescription}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormField
                      fullWidth
                      name="category"
                      label={t("publishing.form.category")}
                      error={errors.category && touched.category}
                      success={!errors.category && touched.category}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormField
                      fullWidth
                      name="keywords"
                      label={t("publishing.form.keywords")}
                      error={errors.keywords && touched.keywords}
                      success={!errors.keywords && touched.keywords}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormField
                      fullWidth
                      name="supportEmail"
                      label={t("publishing.form.email")}
                      error={errors.supportEmail && touched.supportEmail}
                      success={!errors.supportEmail && touched.supportEmail}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormField
                      fullWidth
                      name="privacyUrl"
                      label={t("publishing.form.privacy")}
                      error={errors.privacyUrl && touched.privacyUrl}
                      success={!errors.privacyUrl && touched.privacyUrl}
                    />
                  </Grid>
                </Grid>
              </Card>

              <MDSnackbar
                color="success"
                icon="check"
                title={t("alert.update.title")}
                content={t("alert.update.content")}
                open={openAlert}
                onClose={() => setOpenAlert(false)}
                close={() => setOpenAlert(false)}
                bgWhite
              />

              {error && (
                <MDSnackbar
                  color="error"
                  icon="warning"
                  title={t("alert.update.titleError")}
                  content={error}
                  open={!!error}
                  onClose={() => setError(null)}
                  close={() => setError(null)}
                  bgWhite
                />
              )}
            </MDBox>
          </Form>
        )}
      </Formik>
    </MDBox>
  );
};

export default PublishingGeneral;
