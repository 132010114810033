import { Box, Grid, Icon, Menu, MenuItem } from "@mui/material";
import mockupCarousel from "assets/images/mockupCarousel.png";
import mockupCategory from "assets/images/mockupCategory.png";
import mockupImage from "assets/images/mockupImage.png";
import mockupSearchBar from "assets/images/mockupSearchBar.png";
import mockupText from "assets/images/mockupText.png";
import mockupVideo from "assets/images/mockupVideo.png";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MemoizedSection from "components/Sections/MemoizedSection";
import DraggableSections from "components/Sections/DraggableSections";
import { memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { BLACKCOLOR, WHITECOLOR } from "types/app.type";
import { ETypeSections, ISection } from "../design.type";

interface Props {
  formData: any;
}

const HomePageUpdate = memo(({ formData }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { formField, values, setFieldValue } = formData;
  const { sections } = formField;
  const { sections: sectionsV } = values;
  const [image, setImage] = useState<any>(mockupSearchBar);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => setAnchorEl(null), []);

  const handleMenuItemClick = useCallback(
    (type: ETypeSections) => {
      const sectionsCopy = [...sectionsV];
      const order = sectionsV.length > 1 ? sectionsV[sectionsV.length - 2].order + 1 : 0;

      let defaultStyle = null;
      switch (type) {
        case ETypeSections.CAROUSEL:
          defaultStyle = { type, order, bgColor: WHITECOLOR };
          break;
        case ETypeSections.VIDEO:
          defaultStyle = { type, order, videoUrl: "" };
          break;
        case ETypeSections.CATEGORY:
          defaultStyle = {
            type,
            order,
            bgColor: WHITECOLOR,
            buttonBgColor: WHITECOLOR,
            textColor: BLACKCOLOR,
            borderColor: BLACKCOLOR,
            buttonActiveBgColor: BLACKCOLOR,
            textActiveColor: WHITECOLOR,
            borderActiveColor: BLACKCOLOR,
          };
          break;
        case ETypeSections.SEARCHBAR:
          defaultStyle = { type, order, bgColor: WHITECOLOR, textColor: BLACKCOLOR };
          break;
        case ETypeSections.TEXT:
          defaultStyle = {
            type,
            order,
            bgColor: WHITECOLOR,
            htmlContent: "<p>Lorem ipsum</p>",
            textColor: BLACKCOLOR,
          };
          break;
        case ETypeSections.IMAGE:
          defaultStyle = {
            type,
            order,
            // imageUrl: "",
            imageHeight: 250,
          };
          break;
        default:
          defaultStyle = { type, order, bgColor: WHITECOLOR };
          break;
      }

      sectionsCopy.forEach((section) => {
        if (section.type === ETypeSections.PRODUCTS) section.order = order + 1;
      });

      setFieldValue(sections.name, [...sectionsCopy, defaultStyle]);
      setAnchorEl(null);
    },
    [sectionsV, sections.name, setFieldValue]
  );

  const handleDeleteSection = useCallback(
    (index: number) => {
      const sectionsCopy = [...sectionsV];
      sectionsCopy.splice(index, 1);

      sectionsCopy.forEach((section, idx) => {
        section.order = idx;
      });

      setFieldValue(sections.name, sectionsCopy);
    },
    [sectionsV, sections.name, setFieldValue]
  );

  const handleMenuItemHover = useCallback((itemName: ETypeSections) => {
    const images: Partial<Record<ETypeSections, string>> = {
      [ETypeSections.IMAGE]: mockupImage,
      [ETypeSections.TEXT]: mockupText,
      [ETypeSections.VIDEO]: mockupVideo,
      [ETypeSections.SEARCHBAR]: mockupSearchBar,
      [ETypeSections.CAROUSEL]: mockupCarousel,
      [ETypeSections.CATEGORY]: mockupCategory,
    };
    setImage(images[itemName] || mockupSearchBar); // Valeur par défaut si l'image n'existe pas
  }, []);

  return (
    <>
      <DraggableSections
        sections={sectionsV}
        onReorder={(newSections) => setFieldValue(sections.name, newSections)}
      >
        {sectionsV
          ?.sort((a: ISection, b: ISection) => a.order - b.order)
          .map((section: ISection, index: number) => (
            <MemoizedSection
              key={`section-${section.type}-${index}`}
              section={section}
              index={index}
              setFieldValue={setFieldValue}
              handleDeleteSection={handleDeleteSection}
            />
          ))}
      </DraggableSections>

      <MDBox sx={{ textAlign: "center", mt: 2 }}>
        <MDButton
          variant="gradient"
          color="info"
          sx={{ borderRadius: 50 }}
          onClick={handleClick}
          iconOnly
        >
          <Icon>add</Icon>
        </MDButton>
      </MDBox>

      <Menu id="demo-positioned-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <MenuItem
              disabled={sectionsV.some(
                (section: ISection) => section.type === ETypeSections.SEARCHBAR
              )}
              onMouseEnter={() => handleMenuItemHover(ETypeSections.SEARCHBAR)}
              onTouchStart={() => handleMenuItemHover(ETypeSections.SEARCHBAR)}
              onClick={() => handleMenuItemClick(ETypeSections.SEARCHBAR)}
            >
              {t("design.section.search.title")}
            </MenuItem>
            <MenuItem
              disabled={sectionsV.some(
                (section: ISection) => section.type === ETypeSections.CAROUSEL
              )}
              onMouseEnter={() => handleMenuItemHover(ETypeSections.CAROUSEL)}
              onTouchStart={() => handleMenuItemHover(ETypeSections.CAROUSEL)}
              onClick={() => handleMenuItemClick(ETypeSections.CAROUSEL)}
            >
              {t("design.section.carousel.title")}
            </MenuItem>
            <MenuItem
              disabled={sectionsV.some(
                (section: ISection) => section.type === ETypeSections.CATEGORY
              )}
              onMouseEnter={() => handleMenuItemHover(ETypeSections.CATEGORY)}
              onTouchStart={() => handleMenuItemHover(ETypeSections.CATEGORY)}
              onClick={() => handleMenuItemClick(ETypeSections.CATEGORY)}
            >
              {t("design.section.category.title")}
            </MenuItem>
            <MenuItem
              onMouseEnter={() => handleMenuItemHover(ETypeSections.VIDEO)}
              onTouchStart={() => handleMenuItemHover(ETypeSections.VIDEO)}
              onClick={() => handleMenuItemClick(ETypeSections.VIDEO)}
            >
              {t("design.section.video.title")}
            </MenuItem>
            <MenuItem
              onMouseEnter={() => handleMenuItemHover(ETypeSections.IMAGE)}
              onTouchStart={() => handleMenuItemHover(ETypeSections.IMAGE)}
              onClick={() => handleMenuItemClick(ETypeSections.IMAGE)}
            >
              {t("design.section.image.title")}
            </MenuItem>
            <MenuItem
              // disabled={sectionsV.some((section: ISection) => section.type === ETypeSections.VIDEO)}
              onMouseEnter={() => handleMenuItemHover(ETypeSections.TEXT)}
              onTouchStart={() => handleMenuItemHover(ETypeSections.TEXT)}
              onClick={() => handleMenuItemClick(ETypeSections.TEXT)}
            >
              {t("design.section.text.title")}
            </MenuItem>
          </Grid>
          <Grid
            item
            xs={8}
            style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
          >
            <Box display="flex" pl={2} justifyContent="center" maxWidth="80vw" maxHeight="80vh">
              <MDBox component="img" src={image} alt="Brand" maxWidth={400} maxHeight={400} />
            </Box>
          </Grid>
        </Grid>
      </Menu>
    </>
  );
});

export default HomePageUpdate;
