import { UseQueryResult, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AuthContext } from "context/auth-context/auth.context";
import { useContext } from "react";
import {
  deleteReservation,
  getAllReservations,
  updateReservation,
  getAllEvents,
} from "./reservation.service";
import { IReservation, IEvent, IStatusReservation } from "./reservation.type";

enum KeyQueryReservation {
  getAllReservation = "getAllReservation",
  getAllEvents = "getAllEvents",
}

export const useGetAllReservation = (): UseQueryResult<IReservation[]> => {
  const { clientId } = useContext(AuthContext);

  return useQuery([KeyQueryReservation.getAllReservation, clientId], () => {
    if (!clientId) return null;

    return getAllReservations(clientId);
  });
};

export const useDeleteReservation = () => {
  const { clientId } = useContext(AuthContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (reservationId: string) => deleteReservation({ clientId, reservationId }),
    onSuccess: async (result) => {
      queryClient.setQueryData(
        [KeyQueryReservation.getAllReservation, clientId],
        (prevReservations: IReservation[] | undefined) => {
          return prevReservations?.filter((reservation) => reservation.id !== result.id) || [];
        }
      );
    },
  });
};

export const useUpdateReservation = () => {
  const queryClient = useQueryClient();
  const { clientId } = useContext(AuthContext);

  return useMutation<
    IReservation,
    Error,
    { reservationId: string; data: { status: IStatusReservation } }
  >({
    mutationFn: async ({ reservationId, data }) => {
      return updateReservation({ clientId, reservationId, data });
    },
    onSuccess: (result, variables) => {
      queryClient.setQueryData(
        [KeyQueryReservation.getAllReservation, clientId],
        (prevReservations: IReservation[]) => {
          if (!prevReservations) return prevReservations;

          const updatedReservations = prevReservations.map((reservation) => {
            if (reservation._id === variables.reservationId)
              return { ...reservation, status: variables.data.status };
            return reservation;
          });
          return updatedReservations;
        }
      );
    },
  });
};

export const useGetAllEvents = (): UseQueryResult<IEvent[]> => {
  const { clientId } = useContext(AuthContext);

  return useQuery([KeyQueryReservation.getAllEvents, clientId], () => {
    if (!clientId) return null;

    return getAllEvents(clientId);
  });
};
