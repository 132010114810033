import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Chip, Divider, Icon } from "@mui/material";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import SelectColor from "components/Select/SelectColor";
import { ISection } from "pages/design/design.type";
import { memo } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  section: ISection;
  setFieldValue: any;
  index: number;
  handleDeleteSection: (index: number) => void;
}

const SectionCategory = memo(
  ({ section, setFieldValue, index, handleDeleteSection }: Props): JSX.Element => {
    const { t } = useTranslation();
    const {
      bgColor,
      buttonActiveBgColor,
      buttonBgColor,
      textActiveColor,
      textColor,
      borderActiveColor,
      borderColor,
    } = section;

    return (
      <>
        <MDBox>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Grid container spacing={3}>
                <Grid item xs={10}>
                  <MDTypography variant="body2">{t("design.section.category.title")}</MDTypography>
                </Grid>
                <Grid item>
                  <MDBox
                    color="error"
                    size="medium"
                    onClick={() => handleDeleteSection(index)}
                    sx={{ height: 20 }}
                    alignItems={"center"}
                    alignContent={"center"}
                  >
                    <Icon>delete</Icon>
                  </MDBox>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <MDBox>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <SelectColor
                      fieldName={`sections[${index}].bgColor`}
                      label={t("form.bgColor")}
                      value={bgColor || "#ffffff"}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                  <Grid container justifyContent="center" pt={2}>
                    <Grid item xs={12} sm={8} pb={2} sx={{ textAlign: "center" }}>
                      <Divider>
                        <Chip label={t("design.section.category.defaultButton")} />
                      </Divider>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <SelectColor
                      fieldName={`sections[${index}].buttonBgColor`}
                      label={t("form.buttonBgColor")}
                      value={buttonBgColor || "#ffffff"}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <SelectColor
                      fieldName={`sections[${index}].textColor`}
                      label={t("form.textColor")}
                      value={textColor || "#ffffff"}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <SelectColor
                      fieldName={`sections[${index}].borderColor`}
                      label={t("form.borderColor")}
                      value={borderColor || "#ffffff"}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>

                  <Grid container justifyContent="center" pt={2}>
                    <Grid item xs={12} sm={8} pb={2} sx={{ textAlign: "center" }}>
                      <Divider>
                        <Chip label={t("design.section.category.activeButton")} />
                      </Divider>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <SelectColor
                      fieldName={`sections[${index}].buttonActiveBgColor`}
                      label={t("form.buttonActiveBgColor")}
                      value={buttonActiveBgColor || "#ffffff"}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <SelectColor
                      fieldName={`sections[${index}].textActiveColor`}
                      label={t("form.textActiveColor")}
                      value={textActiveColor || "#ffffff"}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <SelectColor
                      fieldName={`sections[${index}].borderActiveColor`}
                      label={t("form.borderActiveColor")}
                      value={borderActiveColor || "#ffffff"}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                </Grid>
              </MDBox>
            </AccordionDetails>
          </Accordion>
        </MDBox>
      </>
    );
  }
);

export default SectionCategory;
