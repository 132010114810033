import theme from "assets/theme";
import getCroppedImg from "components/ImageCrop/cropFunc";
import { useEffect, useState } from "react";
import Cropper from "react-easy-crop";

interface Props {
  image: {
    dataURL: string;
  } | null;
  onChange: (value: File) => void;
  aspect?: number;
}

interface CropArea {
  x: number;
  y: number;
  width: number;
  height: number;
}

const CropImage = ({ image, onChange, aspect = 4 / 3 }: Props) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [currentImage, setCurrentImage] = useState<string | null>(null);

  // Reset state when image changes
  useEffect(() => {
    if (image?.dataURL) {
      setCurrentImage(image.dataURL);
      setCrop({ x: 0, y: 0 });
      setRotation(0);
      setZoom(1);
    }
  }, [image]);

  const onCropComplete = async (_: CropArea, croppedAreaPixels: CropArea) => {
    if (!currentImage) return;

    try {
      const croppedFile = await getCroppedImg(
        { dataURL: currentImage },
        croppedAreaPixels,
        rotation
      );

      if (croppedFile) {
        onChange(croppedFile);
      }
    } catch (error) {
      console.error("Erreur lors du crop de l'image:", error);
    }
  };

  if (!currentImage) {
    return null;
  }

  return (
    <div>
      <div
        style={{
          position: "relative",
          width: "100%",
          height: 165,
          background: "#333",
          [theme.breakpoints.up("sm")]: {
            height: 200,
          },
        }}
      >
        <Cropper
          image={currentImage}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          aspect={aspect}
          onCropChange={setCrop}
          onRotationChange={setRotation}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>
    </div>
  );
};

export default CropImage;
