import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import MDTypography from "components/MDTypography";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  color: string;
}
const LanguageSelector = ({ color }: Props) => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (selectedLanguage: string) => {
    i18n.changeLanguage(selectedLanguage);
    Cookies.set("selectedLanguage", selectedLanguage, { expires: 365 }); // Sauvegarde la langue dans un cookie
  };

  useEffect(() => {
    const savedLanguage = Cookies.get("selectedLanguage");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  return (
    <Select
      value={i18n.language}
      onChange={(e) => changeLanguage(e.target.value as string)}
      renderValue={() => (
        <MDTypography variant="h6" color={color}>
          {t(`language.${i18n.language}`)}
        </MDTypography>
      )}
    >
      <MenuItem value="en">{t("language.en")}</MenuItem>
      <MenuItem value="fr">{t("language.fr")}</MenuItem>
      <MenuItem value="it">{t("language.it")}</MenuItem>
    </Select>
  );
};

export default LanguageSelector;
