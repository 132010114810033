import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Icon, Slider } from "@mui/material";
import Grid from "@mui/material/Grid";
import CropImage from "components/ImageCrop/CropImage";
import MDBox from "components/MDBox";
import MDDropzone from "components/MDDropzone";
import MDTypography from "components/MDTypography";
import { ISection } from "pages/design/design.type";
import { memo, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  section: ISection;
  setFieldValue: any;
  index: number;
  handleDeleteSection: (index: number) => void;
}

const MIN_HEIGHT = 25;
const MAX_HEIGHT = 300;

const SectionImage = memo(
  ({ section, setFieldValue, index, handleDeleteSection }: Props): JSX.Element => {
    const { t } = useTranslation();
    const { bgColor, imageUrl, imageHeight } = section;
    const [defaultImage, setDefaultImage] = useState(imageUrl);
    const [uploadedFile, setUploadedFile] = useState<any>(null);

    const handleHeightChange = (event: Event, newValue: number | number[]) => {
      setFieldValue(`sections[${index}].imageHeight`, newValue);
    };

    const handleImageChange = (fileImage: any) => {
      setUploadedFile(fileImage);
      const fieldName = `sections.${index}.imageUrl`;
      if (fileImage?.dataURL) {
        setFieldValue(fieldName, fileImage.dataURL);
      }
    };

    const handleCropImage = (croppedImage: any) => {
      setFieldValue(`sections[${index}].imageUrl`, croppedImage);
    };

    return (
      <MDBox>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Grid container spacing={3}>
              <Grid item xs={10}>
                <MDTypography variant="body2">{t("design.section.image.title")}</MDTypography>
              </Grid>
              <Grid item>
                <MDBox
                  color="error"
                  size="medium"
                  onClick={() => handleDeleteSection(index)}
                  sx={{ height: 20 }}
                  alignItems="center"
                  alignContent="center"
                >
                  <Icon>delete</Icon>
                </MDBox>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <MDBox>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <MDTypography
                        component="label"
                        variant="button"
                        fontWeight="regular"
                        color="text"
                      >
                        {t("form.image")}
                      </MDTypography>
                      {useMemo(
                        () => (
                          <MDDropzone
                            options={{
                              addRemoveLinks: false,
                              maxFiles: 1,
                              acceptedFiles: "image/png, image/jpg, image/jpeg",
                            }}
                            defaultImageUrl={defaultImage}
                            onChange={handleImageChange}
                          />
                        ),
                        [defaultImage]
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MDTypography
                        component="label"
                        variant="button"
                        fontWeight="regular"
                        color="text"
                      >
                        {t("form.imagePosition")}
                      </MDTypography>
                      <CropImage image={uploadedFile} onChange={handleCropImage} />
                    </Grid>
                  </Grid>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={12}>
                <MDTypography variant="caption" fontWeight="regular">
                  {t("form.imageHeight")}
                </MDTypography>
                <Slider
                  value={imageHeight || 200}
                  onChange={handleHeightChange}
                  min={MIN_HEIGHT}
                  max={MAX_HEIGHT}
                  step={10}
                  marks
                  valueLabelDisplay="auto"
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </MDBox>
    );
  }
);

export default SectionImage;
