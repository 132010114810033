import api from "config/axios.config";
import { IUseDesign } from "./design.type";

export async function updateDesign({
  clientId,
  typeDesign,
  data,
  select,
}: IUseDesign): Promise<any> {
  // Si c'est un FormData, utiliser multipart/form-data
  if (data instanceof FormData) {
    const response = await api.patch(
      `clients/${clientId}/design/${typeDesign}${select ? `?select=${select}` : ""}`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  }

  // Sinon, utiliser l'approche JSON normale
  const response = await api.patch(
    `clients/${clientId}/design/${typeDesign}${select ? `?select=${select}` : ""}`,
    data
  );
  return response.data;
}
