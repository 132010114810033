import MDBox from "components/MDBox";
import { ISection } from "pages/design/design.type";
import { memo, useMemo } from "react";

interface Props {
  section?: ISection;
}

const MockupSectionVideo = memo(({ section }: Props): JSX.Element => {
  const custom = {
    bgColor: section?.bgColor || "#ffffff",
    videoUrl: section?.videoUrl || "",
  };

  const videoConfig = useMemo(() => {
    if (!custom.videoUrl) return { type: null, id: null };

    try {
      const url = new URL(custom.videoUrl);

      if (url.hostname.includes("youtube.com")) {
        return {
          type: "youtube",
          id: url.searchParams.get("v"),
        };
      }

      if (url.hostname === "youtu.be") {
        return {
          type: "youtube",
          id: url.pathname.slice(1),
        };
      }

      if (custom.videoUrl.toLowerCase().endsWith(".mp4")) {
        return {
          type: "mp4",
          id: custom.videoUrl,
        };
      }

      return { type: null, id: null };
    } catch {
      return { type: null, id: null };
    }
  }, [custom.videoUrl]);

  if (!videoConfig.type || !videoConfig.id) {
    return (
      <MDBox bgColor={custom.bgColor} display="flex" justifyContent="center" alignItems="center">
        URL de vidéo invalide
      </MDBox>
    );
  }

  return (
    <MDBox bgColor={custom.bgColor} sx={{ width: "100%", height: "100%" }}>
      <div style={{ position: "relative", width: "100%", paddingTop: "56.25%" }}>
        {videoConfig.type === "youtube" ? (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              pointerEvents: "none",
            }}
          >
            <iframe
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                border: "none",
              }}
              src={`https://www.youtube.com/embed/${videoConfig.id}?autoplay=1&mute=1&loop=1&playlist=${videoConfig.id}&controls=0&disablekb=1&fs=0&modestbranding=1&playsinline=1&iv_load_policy=3&rel=0&showinfo=0`}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              frameBorder="0"
            />
          </div>
        ) : (
          <video
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
              pointerEvents: "none",
            }}
            autoPlay
            muted
            loop
            playsInline
            controls={false}
          >
            <source src={videoConfig.id} type="video/mp4" />
          </video>
        )}
      </div>
    </MDBox>
  );
});

export default MockupSectionVideo;
