import { ETypeSections, ISection } from "pages/design/design.type";
import { memo, useMemo } from "react";
import SectionText from "components/Sections/SectionText";
import SectionVideo from "components/Sections/SectionVideo";
import SectionCarousel from "components/Sections/SectionCarousel";
import SectionImage from "components/Sections/SectionImage";
import SectionCategory from "components/Sections/SectionCategory";
import SectionProduct from "components/Sections/SectionProduct";
import SectionSearchBar from "components/Sections/SectionSearchBar";

interface MemoizedSectionProps {
  section: ISection;
  index: number;
  setFieldValue: (field: string, value: any) => void;
  handleDeleteSection: (index: number) => void;
}

const componentMap = {
  [ETypeSections.VIDEO]: SectionVideo,
  [ETypeSections.TEXT]: SectionText,
  [ETypeSections.IMAGE]: SectionImage,
  [ETypeSections.CAROUSEL]: SectionCarousel,
  [ETypeSections.CATEGORY]: SectionCategory,
  [ETypeSections.PRODUCTS]: SectionProduct,
  [ETypeSections.SEARCHBAR]: SectionSearchBar,
};

const MemoizedSection = memo(
  ({ section, index, setFieldValue, handleDeleteSection }: MemoizedSectionProps) => {
    const Component = useMemo(() => componentMap[section.type], [section.type]);

    if (!Component) return null;

    return (
      <Component
        section={section}
        index={index}
        setFieldValue={setFieldValue}
        handleDeleteSection={handleDeleteSection}
      />
    );
  },
  (prevProps, nextProps) => {
    // Comparaison profonde des props pertinentes
    return (
      prevProps.index === nextProps.index &&
      prevProps.section.type === nextProps.section.type &&
      JSON.stringify(prevProps.section) === JSON.stringify(nextProps.section)
    );
  }
);

export default MemoizedSection;
