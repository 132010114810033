import Navigation from "components/NavigationPage/Navigation";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useTranslation } from "react-i18next";
import PublishingGeneral from "pages/publishing/components/PublishingGeneral";

const Publishing = () => {
  const { t } = useTranslation();

  const tabs = [
    {
      label: t("publishing.tabs.general"),
      content: <PublishingGeneral />,
      mode: "inside",
    },
    {
      label: t("publishing.tabs.visuals"),
      content: <PublishingGeneral />,
      mode: "inside",
    },
    {
      label: t("publishing.tabs.availability"),
      content: <PublishingGeneral />,
      mode: "inside",
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Navigation stickyNavbar={true} tabs={tabs}>
        <Footer />
      </Navigation>
    </DashboardLayout>
  );
};

export default Publishing;
