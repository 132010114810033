import { DataFeatures } from "./client.type";

// Business Rule : List of our functionalities
export enum EFeatures {
  PRODUCTDISPLAY = "productDisplay",
  ONLINEPAYMENT = "onlinePayment",
  ACCOUNT = "accountManagement",
  MAPS = "maps",
  RENT = "rental",
  BOOKINGAPPOINTMENT = "appointmentBooking",
  PUSHNOTIFICATION = "pushNotification",
  SHOPIFY = "shopify",
  LOYALTYCARD = "loyaltyCard",
  CHAT = "chat",
}
export interface IFeaturesComponent {
  clientId: string;
  data: DataFeatures | undefined;
  params: IFeaturesConst;
}

export interface IFeaturesConst {
  id: EFeatures;
  name: string;
  component: (value: IFeaturesComponent) => JSX.Element;
  constants: any;
}

export interface IFeaturesLang {
  fr: IFeaturesConst[];
  en: IFeaturesConst[];
}
