import MDBox from "components/MDBox";
import MDDropzoneRoot from "components/MDDropzone/MDDropzoneRoot";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context/materialUI-context/materialUI.context";
import Dropzone from "dropzone";
import "dropzone/dist/dropzone.css";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  options: {
    [key: string | number]: any;
  };
  onChange?: (fileImage: Dropzone.DropzoneFile) => void;
  defaultImageUrl?: string;
  maxFilesize?: number;
  error?: string;
}

const MDDropzone = ({
  options,
  defaultImageUrl,
  onChange,
  maxFilesize = 2,
  error,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [addImage, setAddImage] = useState(false);
  const [errorAlertContent, setErrorAlertContent] = useState<string | null>(null);

  const dropzoneRef = useRef<HTMLFormElement | null>(null);
  let dz: Dropzone;
  const createDropzone = () => {
    dz = new Dropzone(dropzoneRef.current, {
      ...options,
      autoProcessQueue: false,
      maxFilesize,
    });

    dz.on("addedfile", async (file) => {
      const files = dz.getQueuedFiles();
      if (files.length > 0) dz.removeFile(files[0]);
      startUploadProcess(file);
    });

    dz.on("complete", function (file) {
      if (file.status !== "error") {
        setErrorAlertContent(null);
        setAddImage(true);
        onChange(file);
      }
    });

    dz.on("error", (file, error) => {
      dz.removeFile(file);
      const errorString = typeof error === "string" ? error : error.message;
      const size = (file.size / (1024 * 1024)).toFixed(2);
      if (errorString.includes("File is too")) {
        setErrorAlertContent(t("alert.upload.sizeError", { size, maxFilesize }));
      } else if (errorString.includes("type")) setErrorAlertContent(t("alert.upload.typeError"));
      else setErrorAlertContent(errorString);
    });

    return dz;
  };

  useEffect(() => {
    const removeDropzone = () => {
      if (Dropzone.instances.length > 0) Dropzone.instances.forEach((dz: any) => dz.destroy());
    };

    const dzInstance = createDropzone();

    return () => {
      if (dzInstance) dzInstance.destroy();
      removeDropzone();
      setAddImage(false);
    };
  }, [options]);

  const startUploadProcess = (file: Dropzone.DropzoneFile) => {
    dz?.emit("uploadprogress", file, 0);

    let progress = 0;
    const interval = setInterval(() => {
      progress += 20;
      dz?.emit("uploadprogress", file, progress);

      if (progress === 100) {
        clearInterval(interval);
        dz?.emit("complete", file);
      }
    }, 100);
  };

  return (
    <>
      <MDDropzoneRoot
        action="/file-upload"
        ref={dropzoneRef}
        className="form-control dropzone"
        ownerState={{ darkMode }}
      >
        {!addImage && defaultImageUrl && (
          <MDBox
            component="img"
            src={defaultImageUrl}
            alt="image"
            borderRadius="20px"
            width="120px"
            height="120px"
            style={{
              objectFit: "contain",
              objectPosition: "center",
            }}
          />
        )}
      </MDDropzoneRoot>
      <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
        {errorAlertContent}
      </MDTypography>
    </>
  );
};

export default MDDropzone;
