const form = {
  formId: "design-navigation-form",
  formField: {
    description: {
      name: "description",
      type: "text",
      errorMsgKey: "validationSchema.required.description",
      placeholder: "Description",
    },
    address: {
      name: "address",
      type: "text",
      placeholder: "Address",
    },
    linkAddress: {
      name: "linkAddress",
      type: "text",
      placeholder: "Link to address",
    },
    webSite: {
      name: "webSite",
      type: "text",
      placeholder: "Website URL",
    },
    twitter: {
      name: "twitter",
      type: "text",
      placeholder: "Twitter URL",
    },
    facebook: {
      name: "facebook",
      type: "text",
      placeholder: "Facebook URL",
    },
    instagram: {
      name: "instagram",
      type: "text",
      placeholder: "Instagram URL",
    },
    linkedin: {
      name: "linkedin",
      type: "text",
      placeholder: "LinkedIn URL",
    },
    bgColorCard: {
      name: "bgColorCard",
      type: "color",
      errorMsgKey: "validationSchema.required.bgColor",
      placeholder: "Select color",
    },
    openingHours: {
      name: "openingHours",
      type: "array",
      errorMsgKey: "validationSchema.required.openingHours",
      placeholder: "Opening hours",
    },
  },
} as const;

export default form;
