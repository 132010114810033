import MockupPhone from "components/MockupPhone/MockPhone";
import MockupSectionCarousel from "components/MockupPhone/MockupSectionCarousel";
import MockupSectionCategory from "components/MockupPhone/MockupSectionCategory";
import MockupSectionProduct from "components/MockupPhone/MockupSectionProduct";
import MockupSectionSearchBar from "components/MockupPhone/MockupSectionSearchBar";
import MockupSectionVideo from "components/MockupPhone/MockupSectionVideo";
import MockupSectionText from "components/MockupPhone/MockupSectionText";
import MockupSectionImage from "components/MockupPhone/MockupSectionImage";
import { memo, useMemo } from "react";
import { ETypeSections, ISection } from "../design.type";

interface SectionProps {
  section: ISection;
}

const MemoizedSection = memo(
  ({ section }: SectionProps) => {
    switch (section.type) {
      case ETypeSections.CAROUSEL:
        return <MockupSectionCarousel section={section} />;
      case ETypeSections.CATEGORY:
        return <MockupSectionCategory section={section} />;
      case ETypeSections.SEARCHBAR:
        return <MockupSectionSearchBar section={section} />;
      case ETypeSections.VIDEO:
        return <MockupSectionVideo section={section} />;
      case ETypeSections.PRODUCTS:
        return <MockupSectionProduct section={section} />;
      case ETypeSections.TEXT:
        return <MockupSectionText section={section} />;
      case ETypeSections.IMAGE:
        return <MockupSectionImage section={section} />;
      default:
        return null;
    }
  },
  (prevProps, nextProps) => {
    // Comparaison personnalisée pour éviter les re-renders inutiles
    return JSON.stringify(prevProps.section) === JSON.stringify(nextProps.section);
  }
);

interface Props {
  values: {
    sections: ISection[];
  };
}

const MockupPhoneHomePage = memo(({ values }: Props) => {
  const { sections: sectionV } = values;

  // Mémoiser le tri des sections
  const sortedSections = useMemo(() => {
    return sectionV?.sort((a: ISection, b: ISection) => a.order - b.order) || [];
  }, [sectionV]);

  return (
    <MockupPhone>
      {sortedSections.map((section) => (
        <div key={`${section.type}-${section.order}`}>
          <MemoizedSection section={section} />
        </div>
      ))}
    </MockupPhone>
  );
});

export default MockupPhoneHomePage;
