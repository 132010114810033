import { Grid } from "@mui/material";
import FormField from "components/FormField/FormField";
import MDBox from "components/MDBox";
import MDDropzone from "components/MDDropzone";
import MDTypography from "components/MDTypography";
import { Form, Formik } from "formik";
import _ from "lodash";
import form from "pages/element/schemas/attribute/form";
import { forwardRef, useImperativeHandle, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useCreateAttribute, useUpdateAttribute } from "../element.hook";
import { IAttribute, IAttributeCreate } from "../element.type";
import * as Yup from "yup";

interface Props {
  onFormSubmit: () => void;
  onError: (error: any) => void;
  currentAttribute?: IAttribute;
}

const ModalCreateOrUpdateAttribute = forwardRef(
  ({ onFormSubmit, onError, currentAttribute }: Props, ref) => {
    const { t } = useTranslation();
    const { formId, formField } = form;
    const { title, subTitle, file } = formField;
    const formikRef = useRef(null);
    const createSubAttribute = useCreateAttribute();
    const updateAttribute = useUpdateAttribute();

    const initialValues: { [x: string]: string } = {
      [title.name]: currentAttribute?.name || "",
      [subTitle.name]: currentAttribute?.value || "",
      [file.name]: null,
    };

    const getValidationSchema = () => {
      if (!currentAttribute) {
        return Yup.object().shape({
          [title.name]: Yup.string().required(t("validation.required")),
          [subTitle.name]: Yup.string().required(t("validation.required")),
        });
      }

      return Yup.object().shape({
        [title.name]: Yup.string(),
        [subTitle.name]: Yup.string(),
      });
    };

    const handleSubmit = async (values: any) => {
      try {
        if (!currentAttribute) {
          // Pour la création
          const attributeData: IAttributeCreate = {
            title: values[title.name],
            subTitle: values[subTitle.name],
          };

          // Ajout du fichier s'il existe
          if (values[file.name]) {
            attributeData.file = values[file.name];
          }

          await createSubAttribute.mutateAsync({ data: attributeData });
        } else {
          // Pour la mise à jour (reste inchangé)
          const updateData: IAttributeCreate = {
            title:
              values[title.name] !== currentAttribute.name
                ? values[title.name]
                : currentAttribute.name,
            subTitle:
              values[subTitle.name] !== currentAttribute.value
                ? values[subTitle.name]
                : currentAttribute.value,
          };

          if (values[file.name]) {
            updateData.file = values[file.name];
          }

          const hasChanges =
            updateData.title !== currentAttribute.name ||
            updateData.subTitle !== currentAttribute.value ||
            updateData.file;

          if (hasChanges) {
            await updateAttribute.mutateAsync({
              attributeId: currentAttribute._id,
              data: updateData,
            });
          }
        }

        if (onFormSubmit) onFormSubmit();
      } catch (error) {
        console.error("Error submitting form:", error);
        if (onError) onError(error);
      }
    };

    useImperativeHandle(ref, () => ({
      async submitForm() {
        if (formikRef.current) {
          await formikRef.current.submitForm();
        }
      },
    }));

    return (
      <MDBox mt={3} mb={1} ml={0.5}>
        <Formik
          initialValues={initialValues}
          validationSchema={getValidationSchema()}
          onSubmit={handleSubmit}
          innerRef={formikRef}
          enableReinitialize={true}
        >
          {({ values, errors, touched, setFieldValue, handleChange, handleBlur }) => (
            <Form id={formId} autoComplete="off">
              <MDBox my={3}>
                <Grid container spacing={6}>
                  <Grid item xs={6}>
                    <Grid item xs={12}>
                      <MDBox mt={1}>
                        <MDTypography
                          component="label"
                          variant="button"
                          fontWeight="regular"
                          color="text"
                        >
                          {t("form.image")}
                        </MDTypography>
                        {useMemo(
                          () => (
                            <MDDropzone
                              options={{
                                addRemoveLinks: false,
                                maxFiles: 1,
                                acceptedFiles: "image/png, image/jpg, image/jpeg, image/svg+xml",
                              }}
                              onChange={(fileImage) => {
                                setFieldValue(file.name, fileImage);
                              }}
                              defaultImageUrl={_.get(currentAttribute, "mediaId.url") || null}
                              error={errors[file.name]}
                            />
                          ),
                          [currentAttribute?.mediaId?.url]
                        )}
                      </MDBox>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid item xs={12}>
                      <FormField
                        type={title.type}
                        label={t("form.title")}
                        name={title.name}
                        value={values[title.name]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors[title.name] && touched[title.name]}
                        success={values[title.name]?.length > 0 && !errors[title.name]}
                      />
                    </Grid>
                    <Grid item xs={12} mt={4}>
                      <FormField
                        type={subTitle.type}
                        label={t("form.subTitle")}
                        name={subTitle.name}
                        value={values[subTitle.name]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors[subTitle.name] && touched[subTitle.name]}
                        success={values[subTitle.name]?.length > 0 && !errors[subTitle.name]}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </MDBox>
            </Form>
          )}
        </Formik>
      </MDBox>
    );
  }
);

export default ModalCreateOrUpdateAttribute;
