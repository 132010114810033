import { Icon } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import FormField from "components/FormField/FormField";
import CropImage from "components/ImageCrop/CropImage";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDDropzone from "components/MDDropzone";
import MDEditor from "components/MDEditor";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import Modal, { EType } from "components/Modal/modal";
import AddAttribute from "pages/element/components/AddAttribute";
import AddCategory from "pages/element/components/AddCategory";
import ModalDelete from "pages/element/components/ModalDelete";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IAttribute, ISubCategory } from "../element.type";

interface Props {
  subCategories: ISubCategory[];
  allAttributes: IAttribute[];
  formData: any;
  defaultImage: string;
  cropImage: (image: string) => void;
  handleDeleteProduct: () => void;
  children: React.ReactNode;
}

export const ProductUpdateInfo = ({
  subCategories,
  formData,
  defaultImage,
  allAttributes,
  cropImage,
  handleDeleteProduct,
  children,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openAlertDelete, setOpenAlertDelete] = useState(false);
  const [infoDelete, setInfoDelete] = useState<string | null>(null);
  const [errorAlertTitle, setErrorAlertTitle] = useState<string | null>(null);
  const [errorAlertContent, setErrorAlertContent] = useState<string | null>(null);

  const { formField, values, errors, touched, setFieldValue, initialValues } = formData;
  const { name, title, price, description, file, attributes, inCarousel } = formField;
  const { description: descriptionI } = initialValues;
  const {
    name: nameV,
    title: titleV,
    price: priceV,
    attributes: attributesV,
    inCarousel: inCarouselV,
    file: fileV,
  } = values;

  const handleDelete = async () => {
    try {
      setInfoDelete(t("alert.delete.content", { item: nameV }));
      await handleDeleteProduct();
      setOpenModalDelete(false);
      setOpenAlertDelete(true);
    } catch (error) {
      setErrorAlertTitle(t("alert.delete.titleError"));
      setErrorAlertContent(t("alert.delete.contentError", { item: nameV }));
    }
  };

  const handleClose = () => setOpenModalDelete(false);

  const renderModalDelete = (
    <Modal
      title={t("element.modalDelete.title")}
      openModal={openModalDelete}
      body={<ModalDelete name={nameV} />}
      handleValid={handleDelete}
      handleClose={handleClose}
      type={EType.delete}
    />
  );

  const renderAlertDelete = (
    <MDSnackbar
      color="success"
      icon="check"
      title={t("alert.delete.title")}
      content={infoDelete}
      open={openAlertDelete}
      onClose={() => setOpenAlertDelete(false)}
      close={() => setOpenAlertDelete(false)}
      bgWhite
    />
  );

  const renderAlertError = (
    <MDSnackbar
      color="error"
      icon="warning"
      title={errorAlertTitle}
      content={errorAlertContent}
      open={!!errorAlertContent}
      onClose={() => setErrorAlertContent(null)}
      close={() => setErrorAlertContent(null)}
      bgWhite
    />
  );

  return (
    <>
      <Card style={{ overflowY: "auto" }}>
        <MDBox p={3}>
          <MDBox>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <MDBox display="flex" justifyContent="flex-start">
                  <MDButton
                    style={{ marginRight: 10 }}
                    variant="gradient"
                    color={inCarouselV ? "success" : "default"}
                    onClick={() => {
                      const newValue = !inCarouselV;
                      setFieldValue(inCarousel.name, newValue);
                    }}
                    iconOnly
                  >
                    <Icon>push_pin</Icon>
                  </MDButton>
                  <MDButton
                    style={{ marginRight: 10 }}
                    variant="gradient"
                    color="info"
                    onClick={() => navigate("/element/new")}
                    iconOnly
                  >
                    <Icon>add</Icon>
                  </MDButton>
                  <MDButton
                    style={{ marginRight: 10 }}
                    variant="gradient"
                    color="error"
                    onClick={() => setOpenModalDelete(true)}
                    iconOnly
                  >
                    <Icon>delete</Icon>
                  </MDButton>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={6}>
                {children}
              </Grid>
            </Grid>
          </MDBox>
          <MDBox mt={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormField
                  type={name.type}
                  label={t("form.name")}
                  name={name.name}
                  value={nameV}
                  placeholder={name.placeholder}
                  error={errors.name && touched.name}
                  success={nameV?.length > 0 && !errors.name}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  type={title.type}
                  label={t("form.title")}
                  name={title.name}
                  value={titleV}
                  placeholder={title.placeholder}
                  error={errors.title && touched.title}
                  success={titleV?.length > 0 && !errors.title}
                />
              </Grid>
            </Grid>
          </MDBox>
          <MDBox mt={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormField
                  type={price.type}
                  label={t("form.price")}
                  name={price.name}
                  // Modifier cette ligne pour gérer explicitement les valeurs null/undefined
                  value={priceV === null || priceV === undefined ? "" : priceV}
                  placeholder={price.placeholder}
                  error={errors.price && touched.price}
                  // Modifier la condition de success pour prendre en compte les valeurs null/undefined
                  success={
                    priceV !== null && priceV !== undefined && priceV.length > 0 && !errors.price
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MDBox mb={3}>
                  <AddCategory formData={formData} subCategories={subCategories} />
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                  {t("form.image")}
                </MDTypography>
                {useMemo(
                  () => (
                    <MDDropzone
                      options={{
                        addRemoveLinks: false,
                        maxFiles: 1,
                        acceptedFiles: "image/png, image/jpg, image/jpeg",
                      }}
                      defaultImageUrl={defaultImage}
                      onChange={(fileImage) => setFieldValue(file.name, fileImage)}
                      error={errors.file}
                    />
                  ),
                  [defaultImage]
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                  {t("form.imagePosition")}
                </MDTypography>

                <CropImage
                  image={fileV || defaultImage}
                  onChange={(value: any) => cropImage(value)}
                  // crop={crop}
                  // zoom={zoom}
                  // aspect={4 / 3}
                  // onCropChange={setCrop}
                  // onCropComplete={onCropComplete}
                  // onZoomChange={setZoom}
                />
              </Grid>
            </Grid>
          </MDBox>
          <MDBox mt={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <MDTypography
                    component="label"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    Description&nbsp;&nbsp;
                  </MDTypography>
                </MDBox>
                <MDEditor
                  value={(content) => setFieldValue(description.name, content)}
                  defaultValue={descriptionI}
                  maxLength={1000}
                />
              </Grid>
            </Grid>
          </MDBox>
          <MDBox mt={3}>
            <AddAttribute
              currentAttributes={attributesV}
              allAttributes={allAttributes}
              onChange={(newAttributes) => setFieldValue(attributes.name, newAttributes)}
            />
          </MDBox>
        </MDBox>
      </Card>
      {renderModalDelete}
      {renderAlertDelete}
      {renderAlertError}
    </>
  );
};

export default ProductUpdateInfo;
// export default React.memo(ProductUpdateInfo);
