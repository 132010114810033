import { Card, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import MDBox from "components/MDBox";
import { useTranslation } from "react-i18next";

interface Props {
  formData: any;
  children: React.ReactNode;
}
export const ProductUpdateSettings = ({ formData, children }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { formField, values, setFieldValue } = formData;
  const { isVisible, isAvailable } = formField;
  const { isVisible: isVisibleV, isAvailable: isAvailableV } = values;

  const handleVisibilityChange = (value: string) => {
    setFieldValue(isVisible.name, value === "true");
  };

  const handleAvailabilityChange = (value: string) => {
    setFieldValue(isAvailable.name, value === "true");
  };

  return (
    <Card>
      <MDBox p={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl variant="standard" fullWidth>
              <InputLabel>{t("form.visibility")}</InputLabel>
              <Select
                value={String(isVisibleV)}
                onChange={(e) => handleVisibilityChange(e.target.value)}
              >
                <MenuItem value="true">{t("element.visible")}</MenuItem>
                <MenuItem value="false">{t("element.hidden")}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="standard" fullWidth>
              <InputLabel>{t("form.availability")}</InputLabel>
              <Select
                value={String(isAvailableV)}
                onChange={(e) => handleAvailabilityChange(e.target.value)}
              >
                <MenuItem value="true">{t("element.available")}</MenuItem>
                <MenuItem value="false">{t("element.unavailable")}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
};
