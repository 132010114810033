import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  AppBar,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Icon,
  IconButton,
  List,
  Paper,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { AuthContext } from "context/auth-context/auth.context";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import { sendMessage } from "../chat.service";

interface Message {
  chatId: string;
  from: string;
  text: string;
  timestamp: Date;
}

const initialConversations = [
  { id: "1", name: "John Doe", lastMessage: "Salut, comment ça va ?" },
  { id: "2", name: "Jane Smith", lastMessage: "Je suis disponible demain." },
  // Ajoutez plus de conversations ici
];

const initialMessages = [
  { from: "John Doe", text: "Salut, comment ça va ?", timestamp: new Date(), chatId: "1" },
  { from: "Vous", text: "Bien, merci ! Et toi ?", timestamp: new Date(), chatId: "1" },

  {
    from: "Jane Smith",
    text: "Je suis disponible demain.",
    timestamp: new Date(),
    chatId: "2",
  },
  { from: "Vous", text: "Parfait, on se voit demain.", timestamp: new Date(), chatId: "2" },
];

const ConversationList = ({
  onSelectConversation,
}: {
  onSelectConversation: (id: string) => void;
}) => (
  <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
    <AppBar
      position="static"
      sx={{
        backgroundColor: "#ffffff",
        color: "#000",
        boxShadow: "none",
        borderBottom: "1px solid #e0e0e0",
      }}
    >
      <Toolbar>
        <Typography variant="h6" sx={{ flexGrow: 1, textAlign: "center" }}>
          Conversations
        </Typography>
      </Toolbar>
    </AppBar>
    <Box sx={{ flex: 1, overflowY: "auto", padding: 1 }}>
      <List sx={{ padding: 0 }}>
        {initialConversations.map((convo) => (
          <Card
            key={convo.id}
            sx={{ marginBottom: 1, cursor: "pointer", borderRadius: 0 }}
            onClick={() => onSelectConversation(convo.id)}
          >
            <CardContent>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                {convo.name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {convo.lastMessage}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </List>
    </Box>
  </Box>
);

const ChatWindow = ({
  chatId,
  messages,
  onSendMessage,
  onBack,
}: {
  chatId: string;
  messages: Message[];
  onSendMessage: (text: string) => void;
  onBack: () => void;
}) => {
  const [newMessage, setNewMessage] = useState("");
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  const handleSendMessage = () => {
    if (newMessage.trim()) {
      onSendMessage(newMessage);
      setNewMessage("");
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const renderMessagesWithDates = () => {
    let lastDate: any = null;

    return messages.map((message, index) => {
      const messageDate = moment(message.timestamp).format("YYYY-MM-DD");
      const time = moment(message.timestamp).format("HH:mm");

      const dateDivider =
        lastDate !== messageDate ? (
          <Box
            key={`divider-${index}`}
            sx={{ display: "flex", alignItems: "center", margin: "20px 0" }}
          >
            <Divider sx={{ flex: 1 }} />
            <Typography sx={{ margin: "0 10px" }} variant="caption">
              {messageDate}
            </Typography>
            <Divider sx={{ flex: 1 }} />
          </Box>
        ) : null;

      lastDate = messageDate;

      return (
        <React.Fragment key={index}>
          {dateDivider}
          <Box
            sx={{
              display: "flex",
              justifyContent: message.from === "Vous" ? "flex-end" : "flex-start",
              mb: 1.5,
            }}
          >
            <Box
              sx={{
                backgroundColor: message.from === "Vous" ? "#d1e7ff" : "#f0f0f0",
                color: "#000",
                padding: "10px 15px",
                borderRadius: "20px",
                maxWidth: "75%",
                position: "relative",
                display: "flex",
                alignItems: "flex-end",
                flexDirection: "column",
              }}
            >
              <Typography variant="body1" sx={{ marginBottom: "4px" }}>
                {message.text}
              </Typography>
              <Typography variant="caption" color="text.secondary" sx={{ alignSelf: "flex-end" }}>
                {time}
              </Typography>
            </Box>
          </Box>
        </React.Fragment>
      );
    });
  };

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        borderRadius: 0,
        overflow: "hidden",
      }}
    >
      <AppBar
        position="static"
        sx={{
          backgroundColor: "#ffffff",
          color: "#000",
          boxShadow: "none",
          borderBottom: "1px solid #e0e0e0",
        }}
      >
        <Toolbar>
          <IconButton edge="start" onClick={onBack} sx={{ display: { xs: "block", md: "none" } }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" sx={{ flexGrow: 1, textAlign: "center" }}>
            Chat avec {initialConversations.find((convo) => convo.id === chatId)?.name}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box sx={{ flex: 1, overflowY: "auto", padding: 2 }}>
        {renderMessagesWithDates()}
        <div ref={messagesEndRef} />
      </Box>
      <Box
        sx={{ padding: 2, display: "flex", alignItems: "center", borderTop: "1px solid #e0e0e0" }}
      >
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Tapez un message..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyPress={handleKeyPress}
          sx={{ marginRight: 1 }}
        />
        <IconButton
          sx={{ backgroundColor: "#357f84", color: "#ffffff" }}
          onClick={handleSendMessage}
        >
          <Icon>send</Icon>
        </IconButton>
      </Box>
    </Paper>
  );
};

const ChatSystem = () => {
  const [selectedConversation, setSelectedConversation] = useState<string | null>(null);
  const [messages, setMessages] = useState<Message[]>(initialMessages);
  const socketRef = useRef<any>(null);
  const { clientId } = useContext(AuthContext);

  // Détecte si l'écran est en mode mobile
  const isMobile = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    socketRef.current = io("http://localhost:3000");

    console.log("socketRef.current:", socketRef.current);

    socketRef.current.on("connect", () => {
      console.log("Connected to WebSocket server");
    });

    socketRef.current.on("disconnect", () => {
      console.log("Disconnected from WebSocket server");
    });

    return () => {
      socketRef.current.disconnect();
    };
  }, [clientId]);

  const handleSendMessage = async (text: string) => {
    console.log("Sending message:", text);

    if (selectedConversation !== null) {
      const newMessage: Message = {
        from: "Vous",
        text,
        timestamp: new Date(),
        chatId: "2", // changer et mettre le vrais chatId
      };

      const messageData: any = {
        chatId: null,
        userId: "6685b65397a650913396beca", // Remplacez par l'ID de l'utilisateur actuel
        sender: "Vous",
        content: text,
        timestamp: new Date(),
      };

      const resultMessage = await sendMessage(clientId, messageData);
      console.log("resultMessage:", resultMessage);

      // Envoyer le message au serveur WebSocket
      socketRef.current.emit("sendMessage", messageData);
      console.log("socketRef.current:", socketRef.current);

      setMessages((prevMessages) => [...prevMessages, newMessage]);
      console.log("messages:", messages);
    }
  };
  console.log("-------messages:", messages);

  const getMessagesForSelectedConversation = () => {
    return messages.filter((message) => message.chatId === selectedConversation);
  };

  return (
    <Grid my={3} container spacing={2} sx={{ height: "76vh" }}>
      {!isMobile || selectedConversation === null ? (
        <Grid item xs={12} md={4}>
          <Card
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "76vh",
              overflowY: "auto",
            }}
          >
            <ConversationList onSelectConversation={setSelectedConversation} />
          </Card>
        </Grid>
      ) : null}

      {!isMobile || selectedConversation !== null ? (
        <Grid item xs={12} md={8}>
          <Card
            style={{ overflowY: "auto" }}
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "76vh",
            }}
          >
            {selectedConversation ? (
              <ChatWindow
                chatId={selectedConversation}
                messages={getMessagesForSelectedConversation()}
                onSendMessage={handleSendMessage}
                onBack={() => setSelectedConversation(null)}
              />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexGrow: 1,
                }}
              >
                <Typography variant="h6" color="text.secondary">
                  Sélectionnez une conversation pour commencer.
                </Typography>
              </Box>
            )}
          </Card>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default ChatSystem;
