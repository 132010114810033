export enum ETypePublishing {
  GENERAL = "general",
  VISUALS = "visuals",
  MONETIZATION = "monetization",
}

export interface Publishing {
  appName: string;
  shortDescription: string;
  longDescription: string;
  category: string;
  keywords: string[];
  supportEmail: string;
  privacyUrl: string;
  appIcon: string;
  screenshots: string[];
  promotionalImages: string[];
  price: number;
  countries: string[];
  inAppPurchase: boolean;
}

export interface IPublishingGeneralForm {
  appName: string;
  shortDescription: string;
  longDescription: string;
  category: string;
  keywords: string;
  supportEmail: string;
  privacyUrl: string;
}

export interface IPublishingUpdateResponse {
  success: boolean;
  message: string;
  data?: IPublishingGeneralForm;
}

export interface IMutationPublishingVariables {
  typePublishing: ETypePublishing;
  data: IPublishingGeneralForm;
  select?: string;
}

export interface IUseUpdatePublishing {
  mutateAsync: (variables: IMutationPublishingVariables) => Promise<IPublishingUpdateResponse>;
  isLoading: boolean;
  isError: boolean;
  error: Error | null;
}
