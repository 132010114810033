import api from "config/axios.config";
import { ETypePublishing, Publishing } from "./publishing.type";
import { IClient } from "types/client.type";

export async function updatePublishing({
  clientId,
  typePublishing,
  data,
  select,
}: {
  clientId: string;
  typePublishing: ETypePublishing;
  data?: Publishing;
  select?: string;
}): Promise<IClient> {
  return api.patch(
    `clients/${clientId}/publishing/${typePublishing}${select ? `?select=${select}` : ""}`,
    data
  );
}
