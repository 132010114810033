import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LanguageIcon from "@mui/icons-material/Language";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import { AppBar, Card, IconButton, Link, Toolbar } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MockupPhone from "components/MockupPhone/MockPhone";
import { memo } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { useTranslation } from "react-i18next";

interface Props {
  formData: any;
  logo: string;
  name: string;
}

const MockupInformation = memo(({ formData, logo, name }: Props): JSX.Element => {
  const { values } = formData;
  const { t } = useTranslation();

  const {
    webSite,
    twitter,
    facebook,
    instagram,
    linkedin,
    description,
    bgColorCard,
    address,
    linkAddress,
    openingHours,
  } = values;

  const translateDay = (day: string) => {
    return t(`myApp.openingHours.${day.toLowerCase()}`);
  };

  const social = [
    { name: "linkedin", link: linkedin, icon: <LinkedInIcon /> },
    { name: "instagram", link: instagram, icon: <InstagramIcon /> },
    { name: "facebook", link: facebook, icon: <FacebookIcon /> },
    { name: "twitter", link: twitter, icon: <TwitterIcon /> },
    { name: "webSite", link: webSite, icon: <LanguageIcon /> },
  ];

  const renderSocial = social.map(({ name, link, icon }) => {
    if (link) {
      return (
        <MDBox
          key={name}
          href={link}
          height={30}
          width={30}
          border={1}
          marginX={1}
          component="a"
          target="_blank"
          display="flex"
          alignItems="center"
          justifyContent="center"
          bgColor="rgba(255,255,255,0.2)"
          borderColor="#ffffff"
          borderRadius="100%"
          rel="noreferrer"
          color="#ffffff"
        >
          {icon}
        </MDBox>
      );
    } else return null;
  });

  const styledDescriptionV = `
    <style>
      ul {
        margin-top: 1em;
      }
    </style>
    ${description}
  `;

  return (
    <MockupPhone showNavigation={false}>
      <MDBox>
        <MDBox
          height={230}
          marginTop={6}
          display="flex"
          flexDirection="column"
          borderRadius={14}
          style={{
            backgroundColor: bgColorCard,
            marginLeft: 6,
            marginRight: 6,
          }}
        >
          <Toolbar
            style={{
              color: "#ffffff",
            }}
          >
            <IconButton edge="start" color="inherit" aria-label="menu">
              <MenuIcon />
            </IconButton>
            <MDTypography variant="overline" color={"#ffffff"}>
              Information
            </MDTypography>
          </Toolbar>
          <MDBox
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            marginTop={-1}
          >
            <MDBox
              component="img"
              src={logo}
              alt="Brand"
              width="70px"
              height="70px"
              style={{
                objectFit: "contain",
                objectPosition: "center",
                marginBottom: 10,
              }}
              borderRadius={5}
            />
            <MDBox>
              <MDTypography variant="h6" fontWeight="regular" color="white" marginTop={-1}>
                {name}
              </MDTypography>
            </MDBox>
            <MDBox display="flex" flexDirection="row" marginTop={1}>
              {renderSocial}
            </MDBox>
          </MDBox>
        </MDBox>
        {address && (
          <Link
            href={linkAddress ? linkAddress : "#"}
            target={linkAddress ? "_blank" : undefined}
            rel={linkAddress ? "noreferrer" : undefined}
            sx={{
              textDecoration: "none",
              cursor: linkAddress ? "pointer" : "default", // Ajout d'un style de curseur conditionnel
            }}
          >
            <MDBox
              display="flex"
              align="center"
              alignItems="center"
              radius={10}
              shadow={"20"} // disabled shadow on Android due to blur overlay + elevation issue
              marginTop={-3}
              flexDirection="column" // Ajout de cette ligne pour empiler verticalement
            >
              <Card
                sx={{
                  pl: 1,
                  pr: 1,
                  width: "80%",
                  backgroundColor: "rgba(255, 255, 255, 0.95)", // Ajout de cette ligne pour la transparence
                  minHeight: 60,
                  justifyContent: "center",
                }}
              >
                <MDTypography fontSize="0.9rem" fontWeight="medium">
                  Adresse
                </MDTypography>
                <MDTypography fontSize="0.8rem">{address}</MDTypography>
              </Card>
            </MDBox>
          </Link>
        )}
        <MDBox pb={3} px={2} pt={2}>
          <MDTypography fontWeight="medium" fontSize="0.9rem">
            {t("myApp.aboutUs")}
          </MDTypography>
          <MDBox
            dangerouslySetInnerHTML={{ __html: styledDescriptionV.replace(/<p>/g, "<p><br>") }}
            sx={{ fontSize: "0.9rem", lineHeight: 1 }}
          />
        </MDBox>
        {openingHours && (
          <MDBox pb={3} px={2} pt={2}>
            <MDTypography fontWeight="medium" fontSize="0.9rem">
              {t("myApp.openingHours.title")}
            </MDTypography>
            <MDBox display="flex" flexDirection="column" gap={1} mt={2}>
              {openingHours.map((hours: any, index: number) => (
                <Card
                  key={hours.day}
                  sx={{
                    py: 0.5,
                    px: 1,
                    backgroundColor:
                      index % 2 === 0 ? "rgba(255, 255, 255, 0.95)" : "rgba(245, 245, 245, 0.95)",
                    borderRadius: "28px",
                    boxShadow: "0px 2px 4px rgba(0,0,0,0.05)",
                    width: "100%",
                  }}
                >
                  <MDBox display="flex" justifyContent="space-between" alignItems="center">
                    <MDTypography fontSize="0.85rem" fontWeight="regular">
                      {translateDay(hours.day)}
                    </MDTypography>
                    <MDTypography fontSize="0.85rem" fontWeight="regular">
                      {hours.open} - {hours.close}
                    </MDTypography>
                  </MDBox>
                </Card>
              ))}
            </MDBox>
          </MDBox>
        )}
      </MDBox>
    </MockupPhone>
  );
});

export default MockupInformation;
