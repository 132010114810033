import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import FormField from "components/FormField/FormField";
import MDBox from "components/MDBox";
import MDEditor from "components/MDEditor";
import MDTypography from "components/MDTypography";
import SelectColor from "components/Select/SelectColor";
import { useTranslation } from "react-i18next";
import { Day, FormData, OpeningHour } from "pages/myApp/myApp.type";

interface Props {
  formData: FormData;
}

const InformationUpdate = ({ formData }: Props): JSX.Element => {
  const { t } = useTranslation();
  const defaultSocial = "https://...";
  const { formField, values, errors, touched, setFieldValue, initialValues } = formData;
  const { description: descriptionI } = initialValues;

  const days: Day[] = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const defaultHours: OpeningHour[] = days.map((day) => ({
    day,
    open: "09:00",
    close: "17:00",
  }));

  const {
    webSite,
    twitter,
    facebook,
    instagram,
    linkedin,
    description,
    bgColorCard,
    address,
    linkAddress,
    openingHours,
  } = formField;
  const {
    webSite: webSiteV,
    twitter: twitterV,
    instagram: instagramV,
    linkedin: linkedinV,
    facebook: facebookV,
    bgColorCard: bgColorCardV,
    address: addressV,
    linkAddress: linkAddressV,
    openingHours: openingHoursV,
  } = values;

  const handleTimeChange = (day: Day, type: "open" | "close", value: string): void => {
    const currentHours = Array.isArray(openingHoursV) ? openingHoursV : defaultHours;
    const updatedHours = currentHours.map((hour) =>
      hour.day === day ? { ...hour, [type]: value } : hour
    );
    setFieldValue(openingHours.name, updatedHours);
  };

  const getTimeValue = (day: Day, type: "open" | "close"): string => {
    if (!Array.isArray(openingHoursV)) return type === "open" ? "09:00" : "17:00";
    const dayHours = openingHoursV.find((x) => x.day === day);
    return dayHours?.[type] || (type === "open" ? "09:00" : "17:00");
  };

  return (
    <>
      <Card style={{ maxHeight: "750px", overflowY: "auto" }}>
        <MDBox p={3}>
          <MDBox>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={8}>
                <MDTypography variant="h5">{t("myApp.titleSocials")}</MDTypography>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox mt={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <FormField
                  type={webSite.type}
                  label={t("myApp.socials.webSite")}
                  name={webSite.name}
                  value={webSiteV?.length == 1 ? "https://" + webSiteV : webSiteV ? webSiteV : ""}
                  placeholder={defaultSocial || webSite.placeholder}
                  success={webSiteV?.length > 0 && !errors.webSite}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <FormField
                  type={instagram.type}
                  label={t("myApp.socials.instagram")}
                  name={instagram.name}
                  value={
                    instagramV?.length == 1 ? "https://" + instagramV : instagramV ? instagramV : ""
                  }
                  placeholder={defaultSocial || instagram.placeholder}
                  success={instagramV?.length > 0 && !errors.instagram}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <FormField
                  type={facebook.type}
                  label={t("myApp.socials.facebook")}
                  name={facebook.name}
                  value={
                    facebookV?.length == 1 ? "https://" + facebookV : facebookV ? facebookV : ""
                  }
                  placeholder={defaultSocial || facebook.placeholder}
                  success={facebookV?.length > 0 && !errors.facebook}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <FormField
                  type={twitter.type}
                  label={t("myApp.socials.twitter")}
                  name={twitter.name}
                  value={twitterV?.length == 1 ? "https://" + twitterV : twitterV ? twitterV : ""}
                  placeholder={defaultSocial || twitter.placeholder}
                  success={twitterV?.length > 0 && !errors.twitter}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <FormField
                  type={linkedin.type}
                  label={t("myApp.socials.linkedin")}
                  name={linkedin.name}
                  value={
                    linkedinV?.length == 1 ? "https://" + linkedinV : linkedinV ? linkedinV : ""
                  }
                  placeholder={defaultSocial || linkedin.placeholder}
                  success={linkedinV?.length > 0 && !errors.linkedin}
                />
              </Grid>
            </Grid>
            <MDBox mt={2}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <SelectColor
                    fieldName={bgColorCard.name}
                    label={t("form.bgColor")}
                    value={bgColorCardV}
                    setFieldValue={setFieldValue}
                  />
                </Grid>
              </Grid>
            </MDBox>
          </MDBox>
        </MDBox>
        <MDBox p={3}>
          <MDBox>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={8}>
                <MDTypography variant="h5">{t("myApp.aboutUs")}</MDTypography>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox mt={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormField
                  type={address.type}
                  label={t("myApp.addressOptional")}
                  name={address.name}
                  value={addressV}
                  placeholder={address.placeholder}
                  success={addressV?.length > 0 && !errors.address}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  type={linkAddress.type}
                  label={t("myApp.linkMapAddress")}
                  name={linkAddress.name}
                  value={linkAddressV}
                  placeholder={linkAddress.placeholder}
                  success={linkAddressV?.length > 0 && !errors.linkAddress}
                />
              </Grid>
            </Grid>
          </MDBox>
          <MDBox mt={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <MDTypography
                    component="label"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    Description&nbsp;&nbsp;
                  </MDTypography>
                </MDBox>
                <MDEditor
                  value={(content) => setFieldValue(description.name, content)}
                  defaultValue={descriptionI}
                  maxLength={1000}
                />
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
        <MDBox p={3}>
          <MDBox>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={8}>
                <MDTypography variant="h5">{t("myApp.openingHours.title")}</MDTypography>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox mt={3}>
            {days.map((day) => (
              <Grid key={day} container spacing={3} mb={2}>
                <Grid item xs={12} sm={2}>
                  <MDTypography variant="subtitle2">
                    {t(`myApp.openingHours.${day.toLowerCase()}`)}
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <FormField
                    type="time"
                    label={t("myApp.openingHours.open")}
                    name={`${openingHours.name}.${day}.open`}
                    value={getTimeValue(day, "open")}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleTimeChange(day, "open", e.target.value)
                    }
                    error={touched.openingHours ? (errors.openingHours as string) : undefined}
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <FormField
                    type="time"
                    label={t("myApp.openingHours.close")}
                    name={`${openingHours.name}.${day}.close`}
                    value={getTimeValue(day, "close")}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleTimeChange(day, "close", e.target.value)
                    }
                    error={touched.openingHours ? (errors.openingHours as string) : undefined}
                  />
                </Grid>
              </Grid>
            ))}
          </MDBox>
        </MDBox>
      </Card>
    </>
  );
};

export default InformationUpdate;
