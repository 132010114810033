import { Backdrop, Box, CircularProgress, Zoom } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import Modal from "components/Modal/modal";
import { AuthContext } from "context/auth-context/auth.context";
import PageLayout from "examples/LayoutContainers/PageLayout";
import { Form, Formik } from "formik";
import CommencerLayout from "layouts/authentication/components/Commencer";
import AppInfo from "pages/create-app/components/AppInfo";
import ModalDescription from "pages/create-app/components/ModalDescription";
import Template from "pages/create-app/components/Template";
import { useCreateClient, useGetVipByEmail } from "pages/create-app/create-app.hook";
import form from "pages/create-app/schemas/form";
import initialValues from "pages/create-app/schemas/initialValues";
import validations from "pages/create-app/schemas/validations";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { IClient, ICreateClient } from "types/client.type";
import ModalAccessDenied from "pages/create-app/components/ModalAccessDenied";

const getSteps = (): string[] => {
  return ["Information", "Template"];
};

const getStepContent = (stepIndex: number, formData: any): JSX.Element => {
  switch (stepIndex) {
    case 0:
      return <AppInfo formData={formData} />;
    case 1:
      return <Template formData={formData} />;
    default:
      return null;
  }
};

const CreateApp = (): JSX.Element => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalAccessDenied, setOpenModalAccessDenied] = useState(false);
  const [isStart, setIsStart] = useState(true);
  const [codeApp, setCodeApp] = useState("");
  const [errorAlert, setErrorAlert] = useState<string | null>(null);
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const createClient = useCreateClient();
  const { currentUserFirebase } = useContext(AuthContext);
  const { data: vipUser } = useGetVipByEmail(currentUserFirebase.email);

  const handleBack = () => setActiveStep(activeStep - 1);
  const validModal = () => {
    setOpenModal(false);
    window.location.reload();
  };

  const validModalAccessDenied = () => {
    setOpenModalAccessDenied(false);
    window.location.href = "https://hopelapp.com/contact-us?subject=Waiting-list";
  };

  const submitForm = async (values: ICreateClient, actions: any) => {
    try {
      actions.setSubmitting(true);
      setIsLoading(true);
      if (!vipUser) {
        setOpenModalAccessDenied(true);
        setIsLoading(false);
      } else {
        const client: IClient = await createClient.mutateAsync(values);
        if (!client) throw new Error("client not create");
        setCodeApp(client.code);
        setOpenModal(true);
        setIsLoading(false);
      }
    } catch (error: any) {
      actions.setSubmitting(false);
      setIsLoading(false);
      setErrorAlert(t("createApp.errorSubmit"));
      setActiveStep(0);
    }
  };

  const handleSubmit = (values: any, actions: any) => {
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  return (
    <PageLayout>
      {
        <MDSnackbar
          color="error"
          icon="warning"
          title="Authentification"
          content={errorAlert}
          open={!!errorAlert}
          onClose={() => setErrorAlert(null)}
          close={() => setErrorAlert(null)}
          bgWhite
        />
      }
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Modal
        title={t("createApp.modalDescription.title")}
        openModal={openModal}
        body={<ModalDescription code={codeApp} />}
        handleValid={validModal}
        handleClose={validModal}
        cancel={false}
      />

      <Modal
        title={t("createApp.modalAccessDenied.title")}
        openModal={openModalAccessDenied}
        body={<ModalAccessDenied />}
        handleValid={validModalAccessDenied}
        handleClose={validModalAccessDenied}
        cancel={false}
      />

      <Box
        sx={{
          height: "100vh",
          backgroundColor: "#357f84",
          position: "relative",
        }}
      >
        <Zoom
          in={isStart}
          style={{
            transitionDelay: isStart ? "500ms" : "0ms",
            position: "absolute",
            zIndex: isStart ? 1 : 0,
          }}
        >
          <Grid container justifyContent="center" alignItems="center" height="100vh">
            <Grid container>
              <Grid item xs={2}></Grid>
              <Grid item xs={8}>
                <CommencerLayout onClick={() => setIsStart(false)} />
              </Grid>
            </Grid>
          </Grid>
        </Zoom>
        <Zoom
          in={!isStart}
          style={{
            transitionDelay: isStart ? "0ms" : "500ms",
            position: "absolute",
            zIndex: isStart ? 0 : 1,
          }}
        >
          <Grid container justifyContent="center" alignItems="center" height="100%">
            <Grid item xs={12} lg={8}>
              <Formik
                initialValues={initialValues}
                validationSchema={currentValidation}
                onSubmit={handleSubmit}
              >
                {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                  <Form id={formId} autoComplete="off">
                    <Card sx={{ height: "100%" }}>
                      <MDBox mx={2} mt={-3}>
                        <Stepper activeStep={activeStep} alternativeLabel>
                          {steps.map((label) => (
                            <Step key={label}>
                              <StepLabel>{label}</StepLabel>
                            </Step>
                          ))}
                        </Stepper>
                      </MDBox>
                      <MDBox p={3}>
                        <MDBox>
                          {getStepContent(activeStep, {
                            values,
                            touched,
                            formField,
                            errors,
                            setFieldValue,
                          })}
                          <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
                            {activeStep === 0 ? (
                              <MDBox />
                            ) : (
                              <MDButton variant="gradient" color="light" onClick={handleBack}>
                                {t("form.back")}
                              </MDButton>
                            )}
                            <MDButton
                              disabled={isSubmitting}
                              type="submit"
                              variant="gradient"
                              color="dark"
                            >
                              {isLastStep ? t("form.validate") : t("form.next")}
                            </MDButton>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </Card>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Zoom>
      </Box>
    </PageLayout>
  );
};

export default CreateApp;
