import { CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { Form, Formik } from "formik";
import { useGetClientById } from "pages/client/client.hook";
import InformationUpdate from "pages/myApp/components/information/InformationUpdate";
import MockupPhoneNavigation from "pages/myApp/components/information/MockupInformation";
import form from "pages/myApp/schemas/information/form";
import validations from "pages/myApp/schemas/information/validations";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { SELECT_INFORMATION } from "types/client.type";
import { useUpdateClientInformation } from "../myApp.hook";
import { FormData, IInformationUpdate, InformationFormValues } from "../myApp.type";

const Information = (): JSX.Element => {
  const { t } = useTranslation();
  const { formId, formField } = form;
  const [showError, setShowError] = useState(false);
  const [openAlertUpdate, setOpenAlertUpdate] = useState(false);
  const [errorAlertTitle, setErrorAlertTitle] = useState<string | null>(null);
  const [errorAlertContent, setErrorAlertContent] = useState<string | null>(null);
  const {
    webSite,
    twitter,
    facebook,
    instagram,
    linkedin,
    description,
    bgColorCard,
    address,
    linkAddress,
    openingHours,
  } = formField;
  const { data: currentInformation, isLoading: isLoadingCurrentDesign } =
    useGetClientById(SELECT_INFORMATION);
  const updateClient = useUpdateClientInformation({ select: SELECT_INFORMATION });

  const initialValues = {
    [webSite.name]: currentInformation?.socials?.webSite,
    [twitter.name]: currentInformation?.socials?.twitter,
    [facebook.name]: currentInformation?.socials?.facebook,
    [instagram.name]: currentInformation?.socials?.instagram,
    [linkedin.name]: currentInformation?.socials?.linkedin,
    [bgColorCard.name]: currentInformation?.design?.information?.bgColorCard || "#000",
    [address.name]: currentInformation?.address?.address,
    [linkAddress.name]: currentInformation?.address?.link,
    [description.name]: currentInformation?.description,
    [openingHours.name]: currentInformation?.openingHours,
  };

  const handleSubmit = async (values: InformationFormValues, { resetForm }: any): Promise<void> => {
    try {
      const result: IInformationUpdate = {
        socials: {
          webSite: values.webSite,
          twitter: values.twitter,
          facebook: values.facebook,
          instagram: values.instagram,
          linkedin: values.linkedin,
        },
        design: {
          information: {
            bgColorCard: values.bgColorCard,
          },
        },
        description: values.description,
        address: {
          address: values.address,
          link: values.linkAddress,
        },
        openingHours: values.openingHours,
      };

      await updateClient.mutateAsync(result);
      resetForm({ values });
      setOpenAlertUpdate(true);
    } catch (error) {
      // Typage plus précis de l'erreur
      const errorMessage = error instanceof Error ? error.message : "Unknown error occurred";
      setErrorAlertTitle(t("alert.update.titleError"));
      setErrorAlertContent(t("alert.update.contentError", { errorMsg: errorMessage }));
    }
  };

  const renderAlertUpdate = (
    <MDSnackbar
      color="success"
      icon="check"
      title={t("alert.update.title")}
      content={t("alert.update.content")}
      open={openAlertUpdate}
      onClose={() => setOpenAlertUpdate(false)}
      close={() => setOpenAlertUpdate(false)}
      bgWhite
    />
  );

  const renderAlertErrorUpdate = (
    <MDSnackbar
      color="error"
      icon="warning"
      title={errorAlertTitle}
      content={errorAlertContent}
      open={!!errorAlertContent}
      onClose={() => setErrorAlertContent(null)}
      close={() => setErrorAlertContent(null)}
      bgWhite
    />
  );

  const handleShowError = () => {
    setTimeout(() => {
      // Display the error only if the data is still missing after 2 seconds.
      setShowError(!currentInformation);
    }, 2000);
  };

  return (
    <MDBox mt={1} mb={5}>
      {isLoadingCurrentDesign ? (
        <MDBox display="flex" justifyContent="center" alignItems="center" height="60vh">
          <CircularProgress color="inherit" />
        </MDBox>
      ) : (
        <>
          {currentInformation ? (
            <Grid container spacing={1}>
              <Formik
                initialValues={initialValues}
                validationSchema={validations}
                onSubmit={handleSubmit}
              >
                {({ values, errors, touched, setFieldValue, dirty, isSubmitting }) => {
                  const formData: FormData = {
                    values,
                    touched,
                    formField,
                    errors,
                    setFieldValue,
                    initialValues: initialValues,
                  };

                  return (
                    <Form id={formId} autoComplete="off">
                      <MDBox my={3}>
                        <MDBox mb={1}>
                          <Grid container spacing={3} alignItems="center">
                            <Grid item xs={12} lg={6}>
                              <MDTypography variant="h4" fontWeight="medium">
                                {t("myApp.tabs.information")}
                              </MDTypography>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                              <MDBox display="flex" justifyContent="flex-end">
                                <MDButton
                                  disabled={!dirty || isSubmitting}
                                  type="submit"
                                  variant="gradient"
                                  color="info"
                                >
                                  {t("form.validate")}
                                  {isSubmitting && (
                                    <CircularProgress
                                      size={24}
                                      sx={{
                                        color: "inherit",
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        marginTop: "-12px",
                                        marginLeft: "-12px",
                                      }}
                                    />
                                  )}
                                </MDButton>
                              </MDBox>
                            </Grid>
                          </Grid>
                        </MDBox>
                        <Grid container spacing={4}>
                          <Grid item xs={12} lg={8}>
                            <InformationUpdate formData={formData} />
                          </Grid>
                          <Grid item xs={12} lg={3} width={500}>
                            <MockupPhoneNavigation
                              formData={formData}
                              logo={currentInformation.logo}
                              name={currentInformation.name}
                            />
                          </Grid>
                        </Grid>
                      </MDBox>
                    </Form>
                  );
                }}
              </Formik>
            </Grid>
          ) : (
            <>
              {handleShowError()}
              {showError ? (
                <>{t("alert.errorMsg.errorGetData")}</>
              ) : (
                <MDBox display="flex" justifyContent="center" alignItems="center" height="60vh">
                  <CircularProgress color="inherit" />
                </MDBox>
              )}
            </>
          )}
        </>
      )}
      {renderAlertUpdate}
      {renderAlertErrorUpdate}
    </MDBox>
  );
};

export default Information;
