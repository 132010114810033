import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AuthContext } from "context/auth-context/auth.context";
import { KeyQueryClient } from "pages/client/client.hook";
import { useContext } from "react";
import { updateClient, updateClientCurrency } from "services/client.service";
import { IInformationUpdate } from "./admin.type";
import { ECurrency, SELECT_INFORMATION } from "types/client.type";

export const useUpdateClientInformation = ({ select }: { select: string }) => {
  const queryClient = useQueryClient();
  const { clientId } = useContext(AuthContext);

  return useMutation({
    mutationFn: (data: IInformationUpdate) => updateClient(clientId, data, select),
    onSuccess: (result) => {
      queryClient.setQueryData([KeyQueryClient.getById, clientId, select], result);
    },
  });
};

export const useUpdateClientCurrency = () => {
  const queryClient = useQueryClient();
  const { clientId } = useContext(AuthContext);

  return useMutation({
    mutationFn: (data: { currency: ECurrency }) =>
      updateClientCurrency(clientId, data, SELECT_INFORMATION),
    onSuccess: (result) => {
      queryClient.setQueryData([KeyQueryClient.getById, clientId, SELECT_INFORMATION], result);
    },
  });
};
