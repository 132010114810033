import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Icon, TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { ISection } from "pages/design/design.type";
import { memo } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  section: ISection;
  setFieldValue: any;
  index: number;
  handleDeleteSection: (index: number) => void;
}

const SectionVideo = memo(
  ({ section, setFieldValue, index, handleDeleteSection }: Props): JSX.Element => {
    const { t } = useTranslation();
    const { videoUrl } = section;

    return (
      <MDBox>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Grid container spacing={3}>
              <Grid item xs={10}>
                <MDTypography variant="body2">{t("design.section.video.title")}</MDTypography>
              </Grid>
              <Grid item>
                <MDBox
                  color="error"
                  size="medium"
                  onClick={() => handleDeleteSection(index)}
                  sx={{ height: 20 }}
                  alignItems="center"
                  alignContent="center"
                >
                  <Icon>delete</Icon>
                </MDBox>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  type="url"
                  label={t("form.videoUrl")}
                  value={videoUrl || ""}
                  onChange={(e) => setFieldValue(`sections[${index}].videoUrl`, e.target.value)}
                  placeholder="https://example.com/video"
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </MDBox>
    );
  }
);

export default SectionVideo;
