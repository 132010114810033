import api from "config/axios.config";
import { Message, Chat } from "./chat.type";

interface MessageData {
  chatId?: string;
  userId: string;
  sender: string;
  content: string;
  timestamp: Date;
}

export const getChats = (clientId: string): Promise<Chat[]> => {
  return api.get(`/clients/${clientId}/chats`);
};

export const getMessages = (clientId: string, chatId: string): Promise<Message[]> => {
  return api.get(`/clients/${clientId}/message/chat/${chatId}`);
};

export const sendMessage = (clientId: string, data: MessageData): Promise<Message> => {
  return api.post(`/clients/${clientId}/message`, data);
};
